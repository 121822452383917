// extracted by mini-css-extract-plugin
export var back = "Photo-module--back--5JJM-";
export var backWrapper = "Photo-module--backWrapper--56585";
export var collectionTitleLink = "Photo-module--collectionTitleLink--1GK-O";
export var date = "Photo-module--date--GyXjO";
export var desc = "Photo-module--desc--dmrTV";
export var details = "Photo-module--details--D4u1D";
export var field = "Photo-module--field--IlV4z";
export var meta = "Photo-module--meta--+w2Z0";
export var title = "Photo-module--title--IeeB-";
export var value = "Photo-module--value--3Zke2";